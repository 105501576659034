<template>
  <div class="wrapper">
    <div class="header">
      <div class="scratch">
        <div class="scratch-cot">
          <vue-scratch-card
            v-if="!isError"
            element-id="scratchWrap"
            :ratio="0.7"
            :move-radius="20"
            :clearCallback="completeFunction"
            result=""
            :coverImg="img"
            coverColor="#D2200D"
          >
            <div slot="result">
              <div class="super">惊喜马上到</div>
            </div>
          </vue-scratch-card>
          <div v-else class="error-prompt">
            <div class="title">
              {{ errorList[errorType].text }}
            </div>
            <div class="desc">
              {{ errorList[errorType].desc }}
            </div>
          </div>
        </div>
      </div>
      <p class="expiration-date">
        有效期：请于
        {{ common.formatDate(coatDetail.effectiveEndTime, '{y}-{m}-{d}') }}
        前刮开兑换
      </p>
    </div>
    <div class="box">
      <div class="announcement">
        <div class="title-wrapper">
          <div class="title">防骗公告</div>
          <div class="line"></div>
        </div>
        <div class="text">
          电子卡是不记名卡，不可挂失，任何人得到券码信息都可以绑定使用，券码信息等同于现金，请妥善保管，
          <div class="thickening">请勿将券码信息发给他人</div>
          。本店从未委托任何机构或个人以"刷单返利”、“刷信誉”等方式购买商品，请广大消费者提高警惕，谨防上当受骗!!
        </div>
      </div>
      <div class="description">
        <div class="title">兑换说明</div>
        <div class="content">
          <p>
            1、券码展示可能存在延迟，若刮开涂层后未看到券码，请联系店铺在线客服。
          </p>
          <p>2、过期失效，兑换成功后不可退换。</p>
        </div>
      </div>
    </div>

    <van-popup
      class="error-popup"
      v-model="errorVisible"
      :close-on-click-overlay="false"
    >
      <div class="title">温馨提示</div>
      <div class="content" v-if="errorList[errorType]">
        <img class="icon" :src="errorList[errorType].icon" alt="" />
        <div class="text">{{ errorList[errorType].text }}</div>
        <div class="desc" v-if="errorList[errorType].desc">
          {{ errorList[errorType].desc }}
        </div>
      </div>
      <div class="footer">
        <div class="button" @click="errorVisible = false">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { checkForSyncLink, comboSyncVerify } from '@/api/SyncLink'

export default {
  data() {
    return {
      img: require('@/assets/img/ECoating/coating_bg.png'),

      coatDetail: {
        effectiveEndTime: null
      },

      isError: false,
      errorType: 0,
      errorVisible: false,
      errorList: [
        {
          icon: require('@/assets/img/ECoating/out_stock_icon.png'),
          text: '活动太火爆目前缺货中...',
          desc: '预计3个工作日内补货完成，请您后续重试~'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '商品不存在！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '商品已退货！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '商品已禁用！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '商品已过期！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '未到刮开时间！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '业务类型错误，请在规定页面使用！'
        },
        {
          icon: require('@/assets/img/ECoating/error_icon.png'),
          text: '活动太火爆了...',
          desc: '系统忙不过来了，请您五分钟后重试~'
        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.code) {
      this.CheckCode()
    } else {
      this.$router.push({ name: 'Error' })
    }
  },
  methods: {
    async completeFunction() {
      const { redeemToken } = this.coatDetail
      comboSyncVerify({
        redeemToken: redeemToken,
        cipher: this.$route.params.code
      }).then(res => {
        const { code } = res
        if (code === 0) {
          this.coatDetail = { ...this.coatDetail, ...res.data }
          this.goShavedDetail()
        } else if ([2].includes(code)) {
          this.openErrorModal(7)
        } else {
          this.$dialog.alert({ message: res.message }).then(() => {
            window.location.reload()
          })
        }
      })
    },
    CheckCode() {
      this.$toast.loading('加载中...')
      checkForSyncLink({
        pageTypeMatchFlag: true,
        redeemCodePageType: 12,
        redeCodeNoPwd: this.$route.params.code
      })
        .then(res => {
          if (res.code === 0) {
            const data = res.data
            this.coatDetail = data
            if (data.token) {
              this.goShavedDetail()
            }
          }
        })
        .finally(() => {
          this.$toast.clear()
        })
    },
    openErrorModal(code) {
      this.errorType = code
      this.isError = true
      this.errorVisible = true
    },
    goShavedDetail() {
      const { token, redeCodeNo } = this.coatDetail
      this.$router.push({
        name: 'comboSyncDetail',
        query: {
          redeCodeNo: redeCodeNo,
          token: token
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  background-color: #ffc473;
  font-family: Source Han Sans CN;
  .header {
    padding-top: 281px;
    width: 100%;
    height: 920px;
    background-image: url('~@/assets/img/ECoating/header_bg.png');
    background-size: 100% 100%;
  }
  .expiration-date {
    color: #613000;
    font-size: 28px;
    margin-top: 74px;
    text-align: center;
  }
  .scratch {
    width: 580px;
    height: 250px;
    margin: 0 auto;
    border-radius: 10px;
    .scratch-cot {
      height: 250px;
      margin-bottom: 23px;
      > img {
        display: block;
        height: 100%;
      }
      .error-prompt {
        display: flex;
        flex-direction: column;
        height: 100%;
        line-height: 1;
        justify-content: center;
        align-items: center;
        background-image: url('~@/assets/img/ECoating/error_bg.png');
        background-size: 100% 100%;
        .title {
          color: #371500;
          font-size: 40px;
        }
        .desc {
          font-size: 26px;
          color: #6e4f36;
          margin-top: 16px;
        }
      }
      #scratchWrap {
        width: 100% !important;
        height: 100% !important;
        .super {
          border-radius: 10px;
          height: 250px;
          line-height: 250px;
          text-align: center;
          font-size: 48px;
          color: #ed471f;
          font-weight: bold;
          background-color: #fff;
        }
      }
    }
  }

  .box {
    margin-top: -185px;
    background: rgba(255, 255, 255, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.66);
    border-radius: 80px 0px 0px 0px;
    padding: 0 38px 37px;
  }
  .announcement {
    padding-top: 118px;
    width: 100%;
    height: 530px;
    margin-top: -13px;
    background-image: url('~@/assets/img/ECoating/announcement_bg.png');
    background-size: 100% 100%;
    .title-wrapper {
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: 9px;
        content: '';
        width: 102px;
        height: 6px;
        z-index: 1;
        transform: translateX(-50%);
        background: linear-gradient(90deg, #fa5128, #f7b733);
      }
      .title {
        position: relative;
        color: #371500;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        z-index: 2;
      }
    }
    .text {
      padding: 0 88px;
      margin-top: 70px;
      font-size: 26px;
      color: #371500;
      .thickening {
        display: inline;
        font-weight: bold;
      }
    }
  }
  .description {
    background-color: #fff;
    border-radius: 30px;
    margin-top: 30px;
    padding: 32px 61px 62px;
    .title {
      width: 297px;
      height: 74px;
      font-size: 40px;
      font-weight: bold;
      color: #371500;
      text-align: center;
      line-height: 74px;
      font-family: Source Han Sans CN;
      margin: 0 auto;
      background-image: url('~@/assets/img/ECoating/title_bg.png');
      background-size: 100% 100%;
    }
    .content {
      margin-top: 48px;
      font-size: 26px;
      color: #371500;
      font-weight: 400;
      line-height: 44px;
    }
  }
}
.error-popup {
  width: 590px;
  background-color: transparent;
  .title {
    width: 550px;
    height: 107px;
    text-align: center;
    line-height: 107px;
    color: #371500;
    font-size: 36px;
    font-weight: bold;
    margin: 0 auto;
    font-family: Source Han Sans CN;
    background-image: url('~@/assets/img/ECoating/popup_header_bg.png');
    background-size: contain;
  }
  .content {
    text-align: center;
    padding: 0 40px 10px;
    background-color: #fff;
    margin: 0 20px;
    .icon {
      width: 80px;
      height: 80px;
      margin: 32px 0 38px;
    }
    .text {
      font-size: 30px;
      color: #371500;
    }
    .desc {
      font-size: 24px;
      color: #999999;
    }
  }
  .footer {
    background-image: url('~@/assets/img/ECoating/popup_footer_bg.png');
    background-size: contain;
    width: 100%;
    height: 148px;
    .button {
      position: absolute;
      bottom: 28px;
      left: 50%;
      height: 80px;
      width: 280px;
      line-height: 80px;
      text-align: center;
      background: linear-gradient(90deg, #ffc14a, #fff6bd, #ffdc7e);
      border: 1px solid rgba(255, 255, 255, 0.9);
      border-radius: 40px;
      transform: translate(-50%);
      color: #371500;
      font-size: 36px;
      font-weight: bold;
    }
  }
}
</style>
